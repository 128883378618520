<template>
    <vx-card title="Wills" v-if="data">
        <vs-table :data="data" stripe class="w-full">
            <template slot="thead">
                <vs-th>Client</vs-th>
                <vs-th>Date</vs-th>
                <vs-th>Coupon/Access Code</vs-th>
                <vs-th>Paid Amount</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        <span v-if="tr.user && tr.user.personaldetails">{{
                            tr.user.personaldetails.full_name
                        }}</span>
                        <small><br />{{ tr.user.email }}</small>
                    </vs-td>

                    <vs-td>
                        <span v-if="tr.paid_date">{{
                            formatDate(tr.paid_date)
                        }}</span>
                    </vs-td>
                    <vs-td>
                        <span v-if="tr.coupon_used">{{
                            tr.coupon_used.code
                        }}</span>
                        /
                        <span
                            v-if="
                                tr.coupon_used && tr.coupon_used.quick_code_used
                            "
                            >{{ tr.coupon_used.quick_code_used.code }}</span
                        >
                    </vs-td>
                    <vs-td
                        ><span v-if="tr.payment_details"
                            >£{{ tr.payment_details.will_amount / 100 }}</span
                        ></vs-td
                    >
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'WillsInvoiceCard',
    mixins: [textFormat],
    props: ['data'],
    methods: {},
}
</script>
