<template>
    <div class="vx-row">
        <div v-if="invoice" class="vx-col w-full flex flex-row-reverse mb-base">
            <vs-button
                icon-pack="feather"
                icon="icon-download"
                @click="downloadWill"
                >Download PDF</vs-button
            >
        </div>
        <div class="vx-col w-full lg:w-1/3 mb-base">
            <div class="vx-row">
                <div
                    v-if="invoice && invoice.invoice_commission_records.length"
                    class="vx-col w-full mb-base"
                >
                    <CommissionsInvoiceCard
                        :data="invoice.invoice_commission_records"
                    />
                </div>
                <div
                    v-if="invoice && invoice.broker_wills.length"
                    class="vx-col w-full"
                >
                    <WillsInvoiceCard :data="invoice.broker_wills" />
                </div>
            </div>
        </div>
        <div class="vx-col w-full lg:w-2/3">
            <InvoiceCard v-if="invoice" :value="invoice.html" />
        </div>
    </div>
</template>

<script>
import { http } from '@/services'
import InvoiceCard from '@/views/main/Finance/Invoices/Invoice/Cards/Invoice'
import CommissionsInvoiceCard from '@/views/main/Finance/Invoices/Invoice/Cards/Commissions'
import WillsInvoiceCard from '@/views/main/Finance/Invoices/Invoice/Cards/Wills'

export default {
    name: 'Invoice',
    components: { WillsInvoiceCard, CommissionsInvoiceCard, InvoiceCard },
    created() {
        if (this.$route.params.pk) this.getInvoice(this.$route.params.pk)
    },
    watch: {},
    computed: {},
    data() {
        return {
            invoice: null,
        }
    },
    methods: {
        getInvoice(id) {
            console.log('get invoice')
            http.get('invoice_detail', { params: { id: id } })
                .then((response) => {
                    this.invoice = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        downloadWill() {
            console.log('download will try')
            if (this.invoice.pdf) {
                console.log('open in new window')
                window.open(this.invoice.pdf, '_blank')
            }
        },
    },
}
</script>
