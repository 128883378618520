<template>
    <vx-card v-if="data" title="Commissions">
        <div v-for="(record, index) in data" :key="index">
            <h5 class="mb-3">
                {{ record.name }}
                <small>{{ formatDate(record.date_sold) }}</small>
            </h5>

            <vs-table
                stripe
                class="w-full mb-6"
                :sst="true"
                :data="data"
                :noDataText="''"
            >
                <vs-th>Product</vs-th>
                <vs-th></vs-th>
                <vs-th>Product Total</vs-th>
                <vs-th>Commission Total</vs-th>

                <vs-tr v-for="(com, index) in record.commissions" :key="index">
                    <vs-td>{{ com.title }}</vs-td>
                    <vs-td> </vs-td>
                    <vs-td> £{{ com.total }}</vs-td>
                    <vs-td> £{{ com.commission }}</vs-td>
                </vs-tr>

                <vs-tr>
                    <vs-td></vs-td>

                    <vs-td> </vs-td>

                    <vs-td class="font-bold">£{{ record.total }}</vs-td>
                    <vs-td class="font-bold">
                        £{{ record.total_commission }}
                    </vs-td>
                </vs-tr>
            </vs-table>
        </div>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'CommissionsInvoiceCard',
    mixins: [textFormat],
    props: ['data'],
}
</script>
